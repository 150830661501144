<template>
  <v-container class="ma-0 pa-0">
    <div v-if="pharmacyPayInfo.length > 0">
      <v-card
        color="white"
        class="rounded-lg mb-2"
        v-for="(offerInvoice, index) in pharmacyPayInfo"
        :key="index"
      >
        <v-row class="ma-0 pa-0 mb-1">
          <v-col cols="8" md="10" sm="10" class="ma-0 pa-0 pl-4 mt-2"
            ><v-card-title
              class="ma-0 pa-0 primary--text text-body-1 font-weight-medium"
              >{{ $t("transaction.service")
              }}{{ offerInvoice.product.name }}</v-card-title
            ></v-col
          >
          <v-col
            cols="4"
            md="2"
            sm="2"
            class="pa-0 text-caption mt-2 text-center"
            ><v-chip small color="success" text-color="white">
              {{ offerInvoice.invoice.amount_due.split(".").join(",") + " €" }}
            </v-chip></v-col
          >
        </v-row>
        <v-row class="ma-0 pa-0 mb-2 secondary--text">
          <v-col cols="12" md="10" sm="10" class="ma-0 pa-0 pl-4"
            ><span class="ma-0 pa-0 text-body-1 font-weight-medium"
              ><span
                class="undeline"
                style="cursor: pointer"
                @click="redirectOffer(offerInvoice.offer)"
                >{{ offerInvoice.position.name }}</span
              ></span
            ></v-col
          >
        </v-row>
        <v-row class="secondary--text ma-0 pa-0">
          <v-col cols="5" class="mb-3 text-caption pa-0 pl-2"
            ><v-icon size="17" color="secondary" class="mx-1"
              >mdi-calendar</v-icon
            >{{
              offerInvoice.invoice.created | moment("DD/MM/YYYY HH:mm")
            }}</v-col
          >
          <v-col
            cols="7"
            md="7"
            sm="7"
            class="mb-3 text-body-2 pa-0 pl-2 text-center"
          >
            <a :href="offerInvoice.invoice.invoice_pdf" target="_blank">
              <span style="cursor: pointer">
                {{ $t("transaction.download_invoice")
                }}<v-icon color="secondary" size="medium" class=""
                  >mdi-download</v-icon
                >
              </span>
            </a>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div v-else>
      <v-row class="secondary--text ma-0 pa-0" justify="center">
        <v-col cols="12" class="mt-1 mb-3 text-caption pa-0 text-left pl-2"
          ><h2>
            {{ $t("transaction.no_movements") }}
          </h2></v-col
        >
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ pharmacyPayInfo: "pharmacy/pharmacyPayInfo" }),
  },
  methods: {
    redirectOffer(offerId) {
      this.$router.push({
        name: "pharmacy-detail",
        params: {
          offer_id: offerId,
        },
      });
    },
    showInvoice(index) {
      this.$refs["a_" + index][0].click();
    },
  },
};
</script>
<style scoped>
.undeline:hover {
  text-decoration: underline;
}
</style>

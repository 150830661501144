<template>
  <v-container fluid class="mb-8">
    <layout :back="true" />
    <!-- <welcome /> -->
    <div class="mx-2">
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="8" md="4" class="ma-0">
          <h3>{{ $t("transaction.my_movements") }}</h3>
        </v-col>
      </v-row>
      <v-row cols="12" class="pa-0 ma-0" justify-sm="center">
        <v-col
          cols="7"
          sm="5"
          md="4"
          class="ma-0 text-subtitle-1"
          v-if="['pharmacygroup'].includes(userType)"
        >
          <span
            @click="getAllTransactions"
            style="cursor: pointer; text-decoration: underline"
            class="font-weight-black"
          >
            {{ $t("transaction.all") }}
          </span>
        </v-col>
      </v-row>
    </div>
    <v-row class="pa-0 ma-0" justify-sm="center">
      <v-col cols="12" sm="8" md="4">
        <transactions-cards v-if="mounted" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Layout component
import layout from "@/components/layouts/Profile.vue";

// Transactions cards
import TransactionsCards from "@/components/profiles/TransactionsCards.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    layout,
    TransactionsCards,
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ userType: "user/type" }),
  },
  async mounted() {
    this.enableLoading();
    try {
      await this.getPharmacyPayInfo(this.childId);
      this.mounted = true;
    } catch {
      this.disableLoading();
    }
    this.disableLoading();
  },
  methods: {
    ...mapActions({ getPharmacyPayInfo: "pharmacy/getPharmacyPayInfo" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    async getAllTransactions() {
      this.getPharmacyPayInfo();
    },
  },
};
</script>
<style scoped></style>
